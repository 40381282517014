import React from "react"
// import { Link } from "gatsby"
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image"
import Layout from "../components/layout"
import {Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {PinterestShareButton, PinterestIcon} from 'react-share'
import {Helmet} from 'react-helmet'






class Category extends React.Component {
  render() {


    const { currentPage, numPages, mainpath } = this.props.pageContext
   const isFirst = currentPage === 1
   const isLast = currentPage === numPages
   const prevPage = currentPage - 1 === 1 ? mainpath : mainpath + (currentPage - 1).toString()
   const nextPage = mainpath + (currentPage + 1).toString()
    return  (
      <Layout searcharray={this.props.pageContext.searcharray} path={this.props.path} menu={this.props.pageContext.menu}>
        <Helmet>
          {this.props.pageContext.metainfo.map((item,i) => {
    if(item.key === 'title') {
      return (
        <title key={i}>{item.value}</title>
      )
    }
    else if(item.key === 'canonical') {
      return (
        <link key={i} rel="canonical" href={this.props.path} />
      )
    }

    else if(item.key === 'description') {
      return (
        <meta key={i} name="description" content={item.value}/>
      )
    }

    else {
      return null;
    }
  })}
        </Helmet>
        <section>
        <h2 className="text-center cattitle">{this.props.pageContext.title}</h2>
      {this.props.data.drupal.nodeQuery.entities.map((item) => {
        return (
          <section key={item.entityUuid} className="catsection" >
            <div className="bordered-container">
            <Row>
              <Col md={6}>

                <div className="imagecont">
                  <PinterestShareButton className="pinterest" media={`${this.props.pageContext.baseurl}${item.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid.src}`} url={`${this.props.pageContext.baseurl}${item.entityUrl.path}`}><PinterestIcon size={32} round={true} /></PinterestShareButton>

                   <Link to={item.entityUrl.path}>
                  <Img alt={item.fieldMainImage.entity.thumbnail.alt} fluid={item.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid}/>
                  </Link>
                </div>

              </Col>
                <Col md={6}>
                  <div className=" h-100 ml-md-3 mt-4 d-flex flex-column justify-content-center align-items-center">

                  <Link className="text-black"  to={item.entityUrl.path}>  <h3 className="mb-5">{item.entityLabel}</h3></Link>




                    </div>
                </Col>


            </Row>
            </div>


          </section>
        )
      })}


      <nav className={`text-center ${numPages <= 1? 'hide' : null}`} role="navigation">

        <div className="nav-links">
          {!isFirst && (
         <Link className="prev page-numbers" to={prevPage} rel="prev">

             <FontAwesomeIcon style={{fontSize:'20px'}} icon={faChevronLeft}/>


              </Link>
              )}
              {Array.from({ length: numPages }, (_, i) => (
       <Link className={`page-numbers ${currentPage-1 === i ? "currentpage" : null }`} key={`pagination-number${i + 1}`} to={`${i === 0 ? mainpath : `${mainpath}${i+1}`}`}>
         {i + 1}
           </Link>
              ))}


              {!isLast && (
            <Link className="next page-numbers" to={nextPage} rel="next">


              <FontAwesomeIcon style={{fontSize:'20px'}} icon={faChevronRight}/>

                </Link>
                  )}


        </div>
      </nav>


    </section>

      </Layout>

    )
  }
}



export default Category

export const query = graphql`
query($categoryid:String!,$offset: Int!, $limit: Int!) {

  drupal {
    nodeQuery(filter: {conditions: [{field: "field_category", value: [$categoryid]},{field: "type", value: "blog"}]}, limit: $limit, offset: $offset) {
      entities {
        ... on DRUPAL_NodeBlog {
          entityLabel
          entityCreated(format: "d M Y")
          entityUuid
          fieldCategory {
                  entity {
                    entityLabel
                    entityUrl {
                      path
                    }
                  }
                }
          fieldMainImage {
            entity {
              thumbnail {
                url
                alt
                imageFile {
                 childImageSharp {
                   fluid(maxWidth: 1200, quality: 100) {
                       ...GatsbyImageSharpFluid
                        }
                 }
               }
              }

            }
          }
          body {
            summary
          }
          entityUrl {
            path
          }
        }
      }
    }
  }

}
`
